
import { Component } from "vue-property-decorator";
import {VueWizard} from "@/vue-wizard";
// Components
import RegisterForm from "@/app/components/register-form/RegisterForm.vue";
import {store} from "@/app/store";


@Component({
    name: "app-register",
    components: { RegisterForm }
})
export default class AppRegister extends VueWizard {}