import { mapActions, mapMutations } from "vuex";
import { Component } from "vue-property-decorator";
import { VueWizard } from "@/vue-wizard";
import { store, storeTypes } from "@/app/store";
import Axios from "axios";
import { BASE_URL_MANAGER } from "@/config";

@Component({
    name: "register-form",
    methods: {
        ...mapMutations(["setUser"]),
        ...mapActions(["login"]),
    },
})
export default class RegisterForm extends VueWizard {

    setUser!: any;

    public user: any = {
        password: '',
        email: '',
        country: 'CO'
    };

    async handleSubmit(e: Event): Promise<any> {
        e.preventDefault();


        try {
            let isValid = await this.validateForm();

            if (isValid != '') {
                this.$notification.warning({
                    placement: "bottomRight",
                    message: "Error",
                    description: isValid
                });

                return false;
            }
            let register_url = `${BASE_URL_MANAGER}/auth/register`;

            Axios.post(register_url, this.user)
                .then(async (res: any) => {
                    let response = res.data;
                    console.log(response);
                    if (response.isSuccess == true) {
                        this.$notification.success({
                            placement: "bottomRight",
                            message: "Success",
                            description: 'Registro realizado con éxito'
                        })

                        this.cleanForm();
                    } else {
                        this.$notification.warning({
                            placement: "bottomRight",
                            message: "Error",
                            description: response.message
                        });
                    }


                })
                .catch(err => {
                    console.log('err', err);
                    this.$notification.warning({
                        placement: "bottomRight",
                        message: "Error",
                        description: err!.message
                    });
                })

        } catch (e) {

        }


    }


    private cleanForm() {
        this.user = {};
    }

    validateForm(): string {
      

        let regexp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        let serchfind = true;

        serchfind = regexp.test(this.user.email);

        if (!serchfind)
            return 'Email inválido';

        if (this.user.password.length < 8) {
            return 'La contraseña tiene que ser mínimo de 8 caracteres';
        }
        if (this.user.password != this.user.confirm_password)
            return 'Las contraseñas deben coincidir';

        return '';
    }

}